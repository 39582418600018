import Seo from "../../components/_App/seo"
import Navbar from "../../components/_App/Navbar"
import React, { useEffect, useState } from "react"
import Layout from "../../components/_App/layout"
import Partner from "../../components/Common/Partner"
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar"
import Footer from "../../components/_App/Footer"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import * as convertStyled from "../../assets/css/convert-case.module.css"
import DownloadAndCopyButtons from "../../helpers/buttons"
import CopiedMessage from "../../helpers/CopiedMessage"


const parent = [
  {
    name: "Tools",
    link: "tools"
  }
]

function JStoTS() {
  const [jsObjectString, setJsObjectString] = useState("")
  const [tsInterface, setTsInterface] = useState("")
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [message, setMessage] = useState("");

  const convertToInterface = (e) => {
    setJsObjectString(e.target.value)
    try {
      const parsedObject = eval("(" + e.target.value + ")")
      const objectWithEmptyArrayHandled = handleEmptyArray(parsedObject)
      const interfacesCode = generateInterfaces(parsedObject)
      setTsInterface(interfacesCode)
    } catch (error) {
      setTsInterface("")
    }
  }

  const  handleEmptyArray  =  ( obj )  =>  {
    const newObj = {}
    for (const key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length === 0) {
        newObj[key] = "any[]"
      } else if (typeof obj[key] === "object") {
        newObj[key] = handleEmptyArray(obj[key])
      } else {
        newObj[key] = obj[key]
      }
    }
    return newObj
  }

  const generateInterfaces = (obj, prefix = "Root") => {
    const interfaceCode = []
    const nestedInterfaces = []

    for (const key in obj) {
      const value = obj[key]
      const type = typeof value

      const propertyType = mapType(type)

      if (type === "object" && !Array.isArray(value) && value !== null) {
        const nestedInterfaceName = `${prefix}_${key}`
        nestedInterfaces.push(generateInterfaces(value, nestedInterfaceName))
        interfaceCode.push(`  ${key}: ${nestedInterfaceName};`)
      } else if (type === "object" && (Array.isArray(value) && value.length === 0)) {
        interfaceCode.push(`  ${key}: any[];`)
      } else {
        interfaceCode.push(`${key}: ${propertyType};`)
      }
    }

    const interfaceName = prefix
    const nestedInterfacesCode = nestedInterfaces.join("\n")

    return `export interface ${interfaceName} {\n${interfaceCode.join(
      "\n"
    )}\n}\n\n${nestedInterfacesCode}`
  }

  const mapType = (jsType) => {
    switch (jsType) {
      case "string":
        return "string"
      case "number":
        return "number"
      case "boolean":
        return "boolean"
      case "object":
        return "any"
      case "undefined":
        return "undefined"
      case "function":
        return "Function"
      case "symbol":
        return "symbol"
      case "bigint":
        return "bigint"
      default:
        return "any"
    }
  }

  useEffect(() => {
    setIsInputEmpty(jsObjectString === "");
  }, [jsObjectString])

  const showMessage = (msg) => {
    setMessage(msg);
  };

  return (
    <Layout>
      <Seo title="Javascript to Typescript Converter Tool" />
      <Navbar />
      <div className="contact-area ptb-80">
        <div className="container py-5">
          <div className="section-title">
            <h1>Javascript Object to Typescript Interface</h1>
            <div className="bar"/>
          </div>
          <Breadcrumbs pageTitle="Javascript Object to Typescript Interface" parent={parent} />
          <div className="row align-items-center">
            <div className="row">
              <div className="col-lg-6">
                <textarea value={jsObjectString} onChange={(e) => convertToInterface(e)}
                          className={convertStyled.text_field} id="" cols="30" rows="10" />
              </div>
              <div className="col-lg-6">
                <textarea name="" value={tsInterface} className={`${convertStyled.text_field}`} id="" cols="30"
                          rows="10" />
                <DownloadAndCopyButtons previewValue={tsInterface}  disabled={isInputEmpty} showMessage={showMessage}/>
                {message && (<CopiedMessage message={message} />)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partner />
      <MilestonesAchievedSoFar />
      <Footer />
    </Layout>
  )
}

export default JStoTS